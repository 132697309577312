import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { API_URL } from 'src/environments/settings';

@Injectable({ providedIn: 'root' })
export class RosterSenioritiesService {

  private apiUrl: string;
  
  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = `${this.env.getValueTransferState(API_URL)}/app/worker`;
  }

  getWorkersByUnion(unionId: number, skipCount: number, maxResultCount: number, searchQ: string, seniorityClass: string) {
    const params: HttpParams = new HttpParams({
      fromObject: {
        UnionId: unionId,
        MaxResultCount: maxResultCount,
        SkipCount: skipCount,
        searchQ: searchQ || '',
        class: seniorityClass
      }
    })
    return this.http.get<any>(`${this.apiUrl}/rosterQuickUpdate`, { params });
  }

  updateWorkerSeniority(workerId: number, unionId: number, seniorityClass: string, senorityNumber: number) {
    const url: string = `${this.apiUrl}/workerQuickUpdate?workerId=${workerId}&unionId=${unionId}`;
    return this.http.post<any>(url, { 
      seniority: senorityNumber,
      class: seniorityClass
    }).toPromise();
  }

  getSeniorityClass(unionId: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/seniorityClassList/${unionId}`);
  }
}
