import { LaborFilters } from "src/app/features/roster/interfaces/labor-filters.interface";

export class LoadWorkersByUnion {
  public static readonly type = "[Roster Seniorities] load by union";
  constructor(
    public skipCount: number,
    public maxResultsCount: number
  ) {}
}

export class UpdateWorkerSeniorityNumber {
  public static readonly type = "[Roster Seniorities] update worker seniority number";
  constructor(
    public workerId: number,
    public unionId: number,
    public seniorityNumber: number,
    public callbackSuccess: () => void,
    public callbackError: (error) => void
  ) {}
}

export class UpdateWorkerSeniorityClass {
  public static readonly type = "[Roster Seniorities] update worker seniority class";
  constructor(
    public workerId: number,
    public unionId: number,
    public seniorityClass: string,
    public callbackSuccess: () => void,
    public callbackError: (error) => void
  ) {}
}

export class UpdateWorkersFilters {
	static readonly type = "[Roster Seniorities] update filters";
	constructor(
		public filters: LaborFilters
	) {}
}

export class LoadSeniorityClass {
  static readonly type = "[Worker Seniorities] load seniority class";
  constructor(
    public unionId: number
  ) {}
}