import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { SelectOption } from "src/app/shared/interfaces/select-option.interface";
import { Worker } from "../../../../core/interfaces/worker.interface";
import { LaborFilters } from "../../interfaces/labor-filters.interface";
import { RosterSenioritiesService } from "../../services/roster-seniorities.service";
import { LoadSeniorityClass, LoadWorkersByUnion, UpdateWorkerSeniorityClass, UpdateWorkerSeniorityNumber, UpdateWorkersFilters } from "./roster-seniorities.actions";

export class RosterSenioritiesStateModel {
  loading: boolean;
  saving: boolean;
  error: boolean;
  
  workers: Array<Worker>;
  totalCount: number;
  filters: LaborFilters;
  seniorityClass: SelectOption[];
}

@Injectable()
@State<RosterSenioritiesStateModel>({
  name: "workersSeniorities",
  defaults: {
    loading: false,
    saving: false,
    error: false,

    workers: [],
    totalCount: 0,
    filters: null,
    seniorityClass: []
  }
})
export class RosterSenioritiesState {
  constructor(
    private rosterSenioritiesService: RosterSenioritiesService
  ) {}

  @Selector() static workers(state: RosterSenioritiesStateModel) { return state.workers; }
  @Selector() static totalWorkersCount(state: RosterSenioritiesStateModel) { return state.totalCount; }
  @Selector() static seniorityClass(state: RosterSenioritiesStateModel) { return state.seniorityClass; }
  @Selector() static filters(state: RosterSenioritiesStateModel) { return state.filters; }
  @Selector() static loading(state: RosterSenioritiesStateModel) { return state.loading; }
  @Selector() static saving(state: RosterSenioritiesStateModel) { return state.saving; }
  @Selector() static error(state: RosterSenioritiesStateModel) { return state.error; }

  @Action(LoadWorkersByUnion)
	loadWorkersByUnion(ctx: StateContext<RosterSenioritiesStateModel>, action: LoadWorkersByUnion) {
		ctx.patchState({
      workers: [],
      loading: true
    });

    const filters: LaborFilters = ctx.getState().filters;
    if (filters?.UnionId) {
      return this.rosterSenioritiesService.getWorkersByUnion(filters?.UnionId, action.skipCount, action.maxResultsCount, filters?.searchQ, filters?.class).pipe(
        tap(
          (response) => {
            ctx.patchState({
              workers: response.items,
              totalCount: response.totalCount,
              loading: false
            });
          },
          (error) => {
            ctx.patchState({
              workers: [],
              totalCount: 0,
              loading: false,
              error: true
            });
          }
        )
      ).subscribe();
    } else {
      ctx.patchState({
        workers: [],
        totalCount: 0,
        loading: false,
        error: false
      });
    }
	}

  @Action(UpdateWorkerSeniorityNumber)
  updateWorkerSeniorityNumber(ctx: StateContext<RosterSenioritiesStateModel>, action: UpdateWorkerSeniorityNumber) {
    return this.rosterSenioritiesService.updateWorkerSeniority(action.workerId, action.unionId, null, action.seniorityNumber).then(
      (response) => { action.callbackSuccess(); },
      (error) => { action.callbackError(error); }
    )
  }

  @Action(UpdateWorkerSeniorityClass)
  updateWorkerSeniorityClass(ctx: StateContext<RosterSenioritiesStateModel>, action: UpdateWorkerSeniorityClass) {
    return this.rosterSenioritiesService.updateWorkerSeniority(action.workerId, action.unionId, action.seniorityClass, null).then(
      (response) => { action.callbackSuccess(); },
      (error) => { action.callbackError(error); }
    )
  }

  @Action(LoadSeniorityClass)
  loadSeniorityClass(ctx: StateContext<RosterSenioritiesStateModel>, action: LoadSeniorityClass) {
    if (action.unionId) {
      return this.rosterSenioritiesService.getSeniorityClass(action.unionId).pipe(
        tap(
          (response) => {
            ctx.patchState({
              seniorityClass: [
                { value: '', text: 'All Class' },
                ...response.map((e) => { return { value: e, text: e } })
              ]
            })
          }
        )
      );
    } else {
      ctx.patchState({
        seniorityClass: []
      });
    }
  }

  @Action(UpdateWorkersFilters)
	updateFilters(ctx: StateContext<RosterSenioritiesStateModel>, action: UpdateWorkersFilters) {
		ctx.patchState({ filters: action.filters });
	}
}
